<template>
  <div id="Report">
    <div class="card">
      <div class="card-content" style="text-align: left;">
        <strong class="title is-4">
          Report
          <!-- <pre>{{isLoadData}}</pre> -->
          <!-- <pre>{{renderChart}}</pre> -->
        </strong>
        <hr>
        <div class="columns is-multiline">
          <div class="column is-one-third-desktop" v-for="(val,key) in branchs" :key="key">
            <article class="message is-info" @click="branch=val">
              <div class="message-header py-1">
                <p class="w-100 cursor_pointer">
                  <i class="fas fa-charging-station"></i>
                  {{val.name}}
                </p>
              </div>
              <div class="message-body has-text-left p-3 cursor_pointer">
                <small class="is-size-6 is-size-7-mobile">{{val.detail}}</small>
              </div>
            </article>
          </div>
        </div>
      </div>
    </div>

    <div class="card mt-5" v-if="branch.id">
      <div class="card-content pb-0" style="text-align: left;">
        <strong class="title is-4">
          <i class="fas fa-charging-station"></i>
          {{branch.name}}
          <br class="is-hidden-tablet">
          <small class="is-size-6">( {{branch.detail}} )</small>
        </strong>
        <hr>
        <div class="columns is-multiline" v-if="node_in_branch.length">
          <div class="column is-one-third-desktop" v-for="(val,key) in node_in_branch" :key="key">
            <article class="message is-link" @click="node=val">
              <div class="message-header py-1">
                <p class="w-100 cursor_pointer">
                  <i class="fas fa-code-branch"></i>
                  {{val.name}} ({{val.mac}})
                </p>
              </div>
              <div class="message-body has-text-left p-3 cursor_pointer">
                <small class="is-size-6 is-size-7-mobile">{{val.detail}}</small>
              </div>
            </article>
          </div>
        </div>
        <div class="columns is-multiline" v-else>
          <div class="message-footer my-4 w-100">
            <center>... Not found device ...</center>
            <br>
            <!-- <button class="button p-6 w-100 is-loading"></button> -->
          </div>
        </div>
      </div>
    </div>

    <div class="card mt-5" v-if="node.id">
      <div class="card-content pb-0" style="text-align: left;">
        <strong class="title is-4">
          <i class="fas fa-code-branch"></i>
          {{node.name}} ({{node.mac}})
          <br class="is-hidden-tablet">
          <small class="is-size-7">({{node.detail}})</small>
        </strong>
        <hr>
      </div>
      <!-- <pre>{{inputDate}}</pre> -->
      <div class="columns is-desktop">
        <div class="column is-full pb-0">
          <button class="button is-small vpdDateButton" @click="previusYear"> <i class="fas fa-caret-left"></i> </button>
          <datepicker
            v-model="inputDate" :format="'yyyy'" minimum-view="year"
            style="width:145px; position: relative; display: inline-block;"
            input-class="input is-small has-text-centered">
          </datepicker>
          <button class="button is-small vpdDateButton" @click="nextYear"> <i class="fas fa-caret-right"></i> </button>
        </div>
      </div>
      <div class="columns is-desktop">
        <div class="column is-full pt-0" v-if="renderChart">
          <div style="width:100%; height:375px;" v-if="!jsonData.length">
            <br><br><br><br>
            <p> <b>Not Found Data</b> </p>
            <small>{{formatYear(inputDate)}}</small>
          </div>
          <small v-else>
            <apexcharts height="375" type="bar" :options="chartOptions" :series="series"></apexcharts>
            <!-- <pre>{{series}}</pre> -->
            <!-- {{jsonData}} -->
            <!-- {{jsonData0}} -->
          </small>
        </div>
        <div class="column is-full py-0" v-else>
          <button class="button is-white is-loading" style="width:100%; height:375px;"></button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
// import DatePick from 'vue-date-pick';
// import 'vue-date-pick/dist/vueDatePick.css';

import Datepicker from 'vuejs-datepicker';
import VueApexCharts from 'vue-apexcharts'

export default {
  name: 'MemberReport',
  props: {
    userData: Object,
    loaded: Object,
    branchs: Array,
    nodes: Array,
    devices: Array,
  },
  // components: { DatePick },
  components: { Datepicker, apexcharts: VueApexCharts, },
  data(){
    return {
      branch: {},
      node: {},
      isLoadData: false,
      renderChart: false,
      inputDate: this.formatDate(new Date()),
      timeData: null,
      jsonData: [],
      jsonData0: [],
      jsonData1: [],
      chartOptions: {
        xaxis: {
          categories: ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"]
        }
      },
      // series: [
      //   {
      //     name: 'series-1',
      //     data: [30, 40, 45, 50, 49, 60, 70, 91, 30, 40, 45, 50, 49, 60, 70, 91]
      //   },
      //   {
      //     name: 'series-2',
      //     data: [31, 41, 41, 51, 41, 61, 71, 90, 31, 41, 41, 51, 41, 61, 71, 90]
      //   }
      // ]
    }
  },
  mounted(){
    if(!this.loaded.get_branch){
      this.$emit('get_branch');
    }
  },
  methods:{
    get_node(n_id){
      this.$emit('get_node',n_id)
    },
    get_device(d_id){
      this.$emit('get_device',d_id)
    },
    async get_report(){
      if(!this.device_in_node.length){
        this.get_device( this.node.id );
        if(!this.isLoadData){
          setTimeout(() => {
            this.isLoadData = true;
            this.get_report();
          }, 1000);
        }
      }else{
        var url = this.api+'user/get_report/'+this.secret;
        var data = new FormData();
        let d_id = [];
        this.device_in_node.forEach((e) => {
          d_id.push(e.id);
        });
        data.set("d_id", JSON.stringify(d_id));
        data.set("year", this.formatYear(this.inputDate) );
        data.set("secret", this.secret);
        this.Axios.post(url, data)
        .then((response)=>{
          console.log(response.data);
          if(response.data.error==0){
            this.jsonData = response.data.data;
            this.jsonData0 = response.data.data0;
            this.jsonData1 = response.data.data1;
          }
          setTimeout(() => this.renderChart=true, 100);
        })
        .catch((error)=>{
          console.error(error);
        })
      }
    },
    formatDate(date=null) {
      var d = new Date(date),
          month = '' + (d.getMonth() + 1),
          day = '' + d.getDate(),
          year = d.getFullYear();

      if (month.length < 2)
          month = '0' + month;
      if (day.length < 2)
          day = '0' + day;

      return [year, month, day].join('-');
    },
    previusYear(){
      var d = new Date(this.inputDate),
          month = '' + (d.getMonth() + 1),
          day = '' + d.getDate(),
          year = d.getFullYear();

      this.inputDate = [year-1, month, day].join('-');
    },
    nextYear(){
      var d = new Date(this.inputDate),
          month = '' + (d.getMonth() + 1),
          day = '' + d.getDate(),
          year = d.getFullYear();

      this.inputDate = [year+1, month, day].join('-');
    },
    formatYear() {
      var d = new Date(this.inputDate);

      return d.getFullYear();
    },
    Total_Energy(log){
      const IMP_ENERGY = (log.Imp_Energy!=="-") ? parseFloat(log.Imp_Energy) : parseInt(0);
      const EXP_ENERGY = (log.Exp_Energy!=="-") ? parseFloat(log.Exp_Energy) : parseInt(0);
      return IMP_ENERGY + EXP_ENERGY;
    },
  },
  computed:{
    node_in_branch(){
      if(!this.branch.id)return [];

      return this.nodes.filter(node => { return parseInt(node.b_id)==parseInt(this.branch.id) });
    },
    count_device_in_branch(){
      if(!this.node_in_branch.length)return 0;

      let count = 0;
      this.node_in_branch.forEach((node) => {
        this.devices.forEach((device) => {
          if(device.n_id==node.id)count++;
        });
      });
      return count;
    },
    device_in_node(){
      if(!this.node.id)return [];

      return this.devices.filter(device => { return parseInt(device.n_id)==parseInt(this.node.id) });
    },
    series(){
      if(!this.jsonData.length)return [];

      let array = [];
      let DEVICE = "Device - ";
      this.jsonData.forEach((e) => {
        let findIndex = array.findIndex(x => x.name == DEVICE+e.d_id);
        if(findIndex<0) array.push({ name: DEVICE+e.d_id, data: [0,0,0,0,0,0,0,0,0,0,0,0] })
      });
      this.jsonData.forEach((e) => {
        let findIndex = array.findIndex(x => x.name == DEVICE+e.d_id);
        if(findIndex>=0){
          let number = 0;
          if(e.mon==1){
            console.log(e.d_id, e.mon, this.jsonData0);
            // number = this.Total_Energy( this.jsonData0[0] );
          }else{
            let check_num = parseFloat( array[findIndex].data[ parseInt(e.mon)-2 ] );
            if(check_num>0) number = check_num;
          }
          array[findIndex].data[ parseInt(e.mon)-1 ] = ( this.Total_Energy(e) - number ).toFixed(2);
        }
      });
      return array;
    }
  },
  watch:{
    branch(val){
      if(val.id){
        this.get_node(val.id);
        this.node = {};
      }
    },
    node(val){
      if(val.id){
        // this.get_device(val.id);
        this.isLoadData = false;
        this.renderChart = false;
        this.get_report();
      }
    },
    inputDate(){
      clearTimeout(this.timeData)
      this.jsonData = [];
      this.isLoadData = false;
      this.renderChart = false;
      this.timeData = setTimeout(() => {
        this.get_report();
      }, 1000)
    }
  }
}
</script>
